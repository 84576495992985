import PropTypes from 'prop-types';
import React from 'react';
import JoinWithDelimiter from 'vivino-js/utilities/JoinWithDelimiter';

import styles from './addressElementsList.scss';

const AddressElementsList = ({ addressArray }) => {
  return (
    <ul className={styles.addressElementsList}>
      {addressArray.map((element, index) => (
        <li key={`address-line-${index}`}>
          {JoinWithDelimiter(
            element.map((lineElement, index) => (
              <span key={`address-line-element-${index}`} data-cy={lineElement.fieldKey}>
                {lineElement.addressFieldValue}
              </span>
            ))
          )}
        </li>
      ))}
    </ul>
  );
};

AddressElementsList.propTypes = {
  addressArray: PropTypes.array,
};

export default AddressElementsList;
