import PropTypes from 'prop-types';
import React from 'react';

const ConfirmedIcon = ({ className }) => (
  <svg
    height="72"
    viewBox="0 0 72 72"
    width="72"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="m46.4478 20.3189v-5.808c0-.677-.633-1.177-1.292-1.02l-1.139.631c-.514.232-1.101.238-1.62.016l-3.132-1.336c-.505-.215-1.077-.215-1.582 0l-3.155 1.346c-.505.216-1.077.216-1.581 0l-3.156-1.346c-.505-.215-1.076-.215-1.581 0l-3.156 1.346c-.505.216-1.076.216-1.581 0l-3.156-1.346c-.505-.215-1.076-.215-1.581 0l-3.155 1.346c-.506.216-1.077.216-1.582 0l-1.159-.621c-.658-.154-1.288.345-1.288 1.021v42.873c0 .668.617 1.165 1.27 1.024l1.13-.566c.514-.233 1.101-.239 1.62-.017l5.132 1.336c.505.216 1.077.216 1.582 0l5.155-1.346c.506-.216 1.076-.216 1.582 0l5.155 1.346c.505.216 1.077.216 1.582 0l6.155-1.346c.505-.216 1.076-.216 1.581 0l5.156 1.346c.505.216 1.076.216 1.581 0l5.155-1.346c.506-.216 1.077-.216 1.582 0l2.219.558c.65.133 1.259-.363 1.259-1.028v-17.143"
        fill="#fafafa"
      />
      <path
        d="m49.4424 60.359c-.377 0-.753-.07-1.108-.21l-5.091-1.329c-.048-.013-.095-.028-.14-.048-.253-.109-.542-.109-.795 0-.058.025-.118.044-.18.057l-6.073 1.328c-.703.272-1.493.268-2.195-.008l-5.09-1.329c-.048-.013-.095-.028-.14-.048-.253-.109-.544-.109-.797 0-.045.02-.092.035-.14.048l-5.091 1.329c-.708.278-1.507.279-2.215 0l-5.068-1.319c-.048-.013-.095-.029-.141-.048-.259-.111-.556-.108-.815.008l-1.093.548c-.076.039-.156.067-.238.084-.611.131-1.236-.018-1.719-.409-.484-.39-.761-.97-.761-1.592v-42.874c0-.629.283-1.214.776-1.605.493-.392 1.129-.532 1.739-.389.085.02.167.051.244.092l1.159.621c.174.071.464.072.718-.038l3.154-1.346c.752-.32 1.616-.32 2.368 0l3.154 1.346c.254.11.544.11.796 0l3.157-1.346c.751-.32 1.613-.32 2.366 0l3.155 1.346c.254.11.544.11.796 0l3.157-1.346c.751-.32 1.614-.32 2.366 0l3.132 1.336c.261.113.558.109.816-.007l1.066-.595c.079-.044.164-.076.252-.098.612-.147 1.249-.006 1.745.385.495.391.779.977.779 1.608v5.808c0 .553-.447 1-1 1-.552 0-1-.447-1-1l-.009-5.842-.936.52c-.841.383-1.724.392-2.498.061l-3.132-1.336c-.254-.109-.544-.109-.796 0l-3.157 1.346c-.75.32-1.613.32-2.366 0l-3.155-1.346c-.254-.109-.544-.109-.796 0l-3.157 1.346c-.751.32-1.613.32-2.366 0l-3.155-1.346c-.253-.108-.543-.109-.796 0l-3.155 1.346c-.753.32-1.616.32-2.368 0l-.995-.53-.058 42.883.009.036.942-.472c.777-.354 1.629-.374 2.385-.074l5.068 1.319c.048.013.095.028.141.048.253.109.542.109.796 0 .045-.02.092-.035.14-.048l5.091-1.329c.707-.28 1.506-.28 2.215 0l5.091 1.329c.048.013.095.028.14.048.254.109.543.109.796 0 .058-.024.118-.044.18-.057l6.072-1.328c.703-.272 1.493-.268 2.195.008l5.091 1.329c.048.013.095.028.141.048.252.109.542.109.794 0 .046-.02.093-.035.141-.048l5.091-1.329c.706-.278 1.503-.279 2.211-.001l2.15.54.015-17.201c0-.553.448-1 1-1 .553 0 1 .447 1 1v17.143c0 .619-.274 1.197-.753 1.587-.478.391-1.101.544-1.706.42l-2.262-.567c-.051-.013-.101-.029-.149-.05-.254-.109-.543-.109-.797 0-.045.02-.092.035-.14.048l-5.09 1.329c-.355.14-.731.21-1.108.21"
        fill="#d0d0cf"
      />
      <path
        d="m60.7925 14.4117c2.024 2.447 3.241 5.587 3.241 9.011 0 7.811-6.332 14.143-14.143 14.143-7.812 0-14.144-6.332-14.144-14.143 0-7.812 6.332-14.144 14.144-14.144 3.352 0 6.432 1.166 8.856 3.115"
        fill="#cdf9de"
      />
      <path
        d="m38.374 40.5621c.552 0 1 .447 1 1s-.448 1-1 1h-20.748c-.552 0-1-.447-1-1s.448-1 1-1zm28.7124-34.7278c.444.328.538.954.21 1.399l-5.2662927 7.1352527c1.9419027 2.6046411 3.0039927 5.7911432 3.0039927 9.0534473 0 8.351-6.794 15.144-15.144 15.144s-15.144-6.793-15.144-15.144c0-8.35 6.794-15.143 15.144-15.143 3.44 0 6.808 1.184 9.482 3.336.431.346.499.975.153 1.406s-.975.498-1.406.152c-2.354-1.893-5.2-2.894-8.229-2.894-7.248 0-13.144 5.896-13.144 13.143 0 7.248 5.896 13.144 13.144 13.144s13.144-5.896 13.144-13.144c0-2.6268464-.7931745-5.1966139-2.2531538-7.3589141l-9.7215462 13.1702141c-.173.234-.44.382-.73.403-.025.002-.05.003-.075.003-.264 0-.519-.104-.707-.293l-6.94-6.94c-.391-.391-.391-1.024 0-1.414.391-.391 1.023-.391 1.414 0l6.118 6.119 15.548-21.066c.328-.444.954-.539 1.399-.211zm-34.6391 26.0823c.552 0 1 .447 1 1s-.448 1-1 1h-14.821c-.553 0-1-.447-1-1s.447-1 1-1zm-2.6607-8.6455c.552 0 1 .447 1 1s-.448 1-1 1h-12.161c-.552 0-1-.447-1-1s.448-1 1-1z"
        fill="#19ca92"
      />
    </g>
  </svg>
);

ConfirmedIcon.propTypes = {
  className: PropTypes.string,
};

export default ConfirmedIcon;
